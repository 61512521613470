<template>
  <a-modal :closable="false" :confirmLoading="verifyLoading" :destroyOnClose="true" :keyboard="false"
           :maskClosable="false"
           :visible="visible" title="验证身份" @cancel="handleCancel" @ok="handleOk">
    <a-form layout="inline">
      <a-form-item label="手机号">
        <a-space>
          <span style="font-weight: bolder; font-size: larger;">{{ maskPhone(phone) }}</span>
          <!--          <a-button :disabled="disabled" :loading="loading" type="primary" @click="sendNotify">{{ sendText }}</a-button>-->
        </a-space>
      </a-form-item>

      <a-form-item label="验证码">
        <a-input-search v-model="code" placeholder="请输入短信验证码" @search="sendNotify">
          <a-button slot="enterButton" :disabled="disabled" :loading="loading" type="primary">
            {{ sendText }}
          </a-button>
        </a-input-search>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import {queryUserPhone, sendPhoneCode, verifyPhoneCode} from "@/api/user";

export default {
  name: "verifyPhone",
  data() {
    return {
      visible: false,
      phone: '',
      status: 0,
      loading: true,
      sendText: '发送验证码',
      disabled: false,
      ticks: 60,
      code: '',
      verifyLoading: false,
    }
  },
  created() {
    this.queryUserPhone()
  },
  methods: {
    toggleVisible() {
      this.visible = !this.visible
    },
    async handleOk(e) {
      if (this.code === '') {
        this.$message.error('请输入验证码')
        return
      }

      this.verifyLoading = true

      verifyPhoneCode({
        phone: this.phone,
        verification: this.code,
      }).then(res => {
        this.$emit('success', this.phone, this.code)
        this.visible = false
      })

      this.verifyLoading = false
    },
    handleCancel(e) {
      this.visible = false;
    },
    maskPhone(phone) {
      return phone.substr(0, 3) + '****' + phone.substr(7)
    },
    async queryUserPhone() {
      this.loading = true

      let data = await queryUserPhone()
      this.phone = data.result

      this.loading = false
    },
    async sendNotify() {
      this.loading = true

      // await new Promise((resolve) => setTimeout(resolve, 2000))
      await sendPhoneCode()

      this.$message.success('验证码发送成功')
      this.loading = false
      this.disabled = true
      this.ticks = 60
      this.sendText = this.ticks + '秒后重试'

      let timer = setInterval(() => {
        if (this.ticks <= 0) {
          clearInterval(timer)
          this.disabled = false
          this.sendText = '发送验证码'
        } else {
          this.ticks -= 1
          this.sendText = this.ticks + '秒后重试'
        }
      }, 1000)

    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.code = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
